<template>
  <div>
    <AppHeader title="实验许可"/>
    <Panel class="tabs">
      <template slot="title">
        <div :class="`tab${tabIndex===0?' active':''}`" @click="switchLicenses(0)">已拥有</div>
        <div :class="`tab${tabIndex===1?' active':''}`" @click="switchLicenses(1)">未拥有</div>
      </template>
      <div slot="content">
        <div class="line">
          <div class="flex-dir-row mt10">
            <div class="flex-item-auto">
              <input class="line-input" id="keyword" v-model="nameLike"   @click="reset" placeholder="仪器名称、简介"/>
            </div>
            <div class="btn btn-small yellow ml5" @click="initBookings">查询</div>
          </div>
        </div>
        <div class="flex-dir-row lic-holder" v-for="(equipment, idx) in equipments" v-if="equipments.length>0">
          <div class="lic-image-holder">
            <img :src="equipment.avatar" v-if="equipment.avatar"/>
            <div class="no-pic" v-else>没有图片</div>
            <div :class="`lic-status ${equipment.statusClass}`" v-if="equipment.statusText">
              {{equipment.statusText}}
            </div>
          </div>
          <div class="flex-item-auto flex-dir-col" style="overflow: hidden;position: relative">
            <div class="lic-info-line lic-title">
              {{ equipment.name }}
            </div>
            <div class="lic-info-line">
              编号：{{ equipment.code }}
            </div>
            <div class="lic-info-line">存放：{{ equipment.locationText }}</div>
            <div class="lic-info-line" v-if="tabIndex===0">期限：{{ equipment.timeLimit }}</div>
            <div class="lic-op-btn green" v-if="equipment.btnTxt" @click="eqAction(equipment)">
              {{ equipment.btnTxt }}
            </div>
            <div class="lic-op-btnnobooking blue" v-if="equipment.btnTxtnobooking" @click="eqAction(equipment)">
              {{ equipment.btnTxtnobooking }}
            </div>
          </div>
        </div>
        <div class="line text-center" v-if="equipments.length===0">暂无记录</div>
      </div>
    </Panel>
    <div class="btn green mt10" v-if="tabIndex===0" @click="switchLicenses(1)">申请许可</div>
  </div>
</template>

<style lang="scss" scoped>
.lic-holder {
  //border-bottom: 1px solid #eee;
  background: #FFFFFF;
  border-radius: 5px;
  margin-top: 15px;
}

.lic-holder:last-child {
  border-bottom: none;
}

.yellow{
  background-color: #A88337;
}

.lic-image-holder {
  width: 125px;
  margin: 12px;
  text-align: center;
  position: relative;
  overflow: hidden;

  img, .no-pic {
    background: #434A54;
    color: #fff;
    height: 80px;
    line-height: 80px;
    border-radius: 6px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.09) !important;
  }

  .lic-status {
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    width: 60px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    opacity: .9;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    font-size: 10px;

    &.no-booking {
      background: #282828;
    }

    &.queue-booking {
      background: #E9573F;
    }

    &.booking {
      background: #3b5998;
    }

    &.wait {
      background: #3b5998;
    }

    &.freeze {
      background: #434A54;
    }

    &.refuse {
      background: #E9573F;
    }
  }
}

.lic-info-line {
  font-size: 12px;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  color: #666;
}

.lic-title {
  overflow: hidden;
  font-size: 15px;
  font-weight: 400;
  color: #000000;
}
.lic-op-btnnobooking{
  position: absolute;
  bottom: 0;
  right: 10px;
  //background-color: #4099ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  width: 60px;
  height: 30px;
  background: #4A89DC;
  border-radius: 4px;
}

.lic-op-btn {
  position: absolute;
  bottom: 0;
  right: 10px;
  //background-color: #4099ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  width: 60px;
  height: 30px;
  background: #70B502;
  border-radius: 4px;
}
</style>

<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {MessageBox, Toast} from 'mint-ui';

export default {
  components: {
    AppHeader,
    Panel
  },
  data() {
    return {
      tabIndex: 0,
      licenses: [],
      equipments: [],
      nameLike: ""
    }
  },
  methods: {
    initBookings() {
      this.bookings = []
      this.page = 1
      this.noMore = false
      this.switchLicenses(this.tabIndex)
    },
    reset() {
      this.nameLike=""
    },
    switchLicenses(tabIndex) {
      const _that = this
      this.tabIndex = tabIndex
      _that.equipments = []
      if (tabIndex === 0) {
        this.$http.get("/ucenter/equip/userLicences?nameLike="+this.nameLike).then(resp => {
          _that.equipments = resp.data.map(function (lic) {
            lic.equipment.timeLimit = lic.timeLimit
            return _that.wrapEquipment(lic.equipment, true)
          })
        })
      } else {
        this.$http.get("/api/equipment/noLicenseEquipments?nameLike="+this.nameLike).then(resp => {
          console.log("equipment",resp)
          _that.equipments = resp.map(function (eq) {
            return _that.wrapEquipment(eq, false)
          })
        })
      }
    },
    wrapEquipment(equipment, mine) {
      equipment.avatar = equipment.avatar ? this.imgUrl + '/' + equipment.avatar : ''
      equipment.purchaseAt = equipment.purchaseAt || '--'
      if (mine) {
        if (equipment.allowNobooking) {
          equipment.statusText = '无预约上机'
          equipment.statusClass = 'no-booking'
          equipment.btnTxtnobooking = '登记'
          equipment.jumpTo = '/booking-plan'
        } else if (equipment.queueCfg && equipment.queueCfg.enabled) {
          equipment.statusText = '排队上机'
          equipment.statusClass = 'queue-booking'
          equipment.btnTxt = ''
          equipment.jumpTo = ''
        } else {
          equipment.statusText = '预约上机'
          equipment.statusClass = 'booking'
          equipment.btnTxt = '预约'
          equipment.jumpTo = '/booking-cal'
        }
      } else {
        // '2': 待审核
        // '3': 已冻结
        // '5': 已拒绝
        if (equipment.status === -1) {
          equipment.btnTxt = '申请'
        } else if (equipment.status === 2) {
          equipment.statusText = '待审核'
          equipment.statusClass = 'wait'
        } else if (equipment.status === 3) {
          equipment.statusText = '已冻结'
          equipment.statusClass = 'freeze'
        } else {
          equipment.statusText = '已拒绝'
          equipment.statusClass = 'refuse'
          equipment.btnTxt = '再申请'
        }
        equipment.jumpTo = ''
      }
      equipment.mine = mine
      return equipment
    },
    eqAction(equipment) {
      if (equipment.mine) {
        if (equipment.jumpTo) {
          this.$router.push({
            path: equipment.jumpTo,
            query: {
              equipmentId: equipment.id
            }
          })
        }
      } else {
        const that = this
        MessageBox.confirm(`确定要申请【${equipment.name}】？`).then(action => {
          that.$http.post(`/ucenter/equip/apply?equipmentId=${equipment.id}`).then(res=>{
            if (res.success){
              let inst = Toast('已申请，请等待审核结果');
              setTimeout(()=>{
                inst.close();
                that.switchLicenses(that.tabIndex)
              }, 2000)
            }
          })
        });
      }
    }
  },
  mounted() {
    this.tabIndex = parseInt(this.$route.query.tabIndex || '0')
    this.switchLicenses(this.tabIndex)
  }
}
</script>
